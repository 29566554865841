<template>
  <div id="app">
    <v-app id="inspire">
      <router-view />
    </v-app>
  </div>
</template>
<script>
export default {
  // 手机端限制移入router中前置守卫处理
  // mounted () {
  // if (this._isMobile()) {
  //   // alert("手机端");
  //   // this.$router.replace('/m_index');
  // } else {
  //   // alert("pc端");
  //   this.$router.replace('/tip');
  // }
  // },
  // methods: {
  //   _isMobile () {
  //     let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  //     return flag;
  //   }
  // }
}
</script>
<style  >
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
}
body {
  overflow: hidden;
}
.v-application--wrap {
  line-height: 0;
  position: static !important;
}
</style>

