import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import 'amfe-flexible'
import echarts from 'echarts'
import "./assets/icon/iconfont.css"
import VueClipvoard from 'vue-clipboard2'
import resCommon from './utils/common.vue'
import Loading from './components/Loading'

Vue.use(VueClipvoard)
Vue.prototype.$echarts = echarts
Vue.prototype.$resCommon = resCommon
Vue.component('Loading', Loading)

Vue.config.productionTip = false
if (module.hot) {
  module.hot.accept();
}

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
