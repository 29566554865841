import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: '/poster',
  },
  {
    path: '/poster',
    component: () => import('../views/Poster'),
  },
  {
    path: '/task',
    component: () => import('../views/Task'),
  },
  {
    path: '/verify',
    component: () => import('../views/Verify'),
  },
  {
    path: '/tip',
    component: () => import('../views/Tip'),
  },
  {
    path: '/my',
    component: () => import('../views/My'),
  },
  {
    path: '/ranings',
    component: () => import('../views/Ranings'),
  },
  {
    path: '/sale',
    component: () => import('../views/Sale'),
  },
  {
    path: '/income',
    component: () => import('../views/Income'),
  },
  {
    path: '/industry-reports',
    component: () => import('../views/IndustryReports'),
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
function _isMobile () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}
router.beforeEach((to, from, next) => {
  // 当页面访问非poster或tip时，需要在移动端进行访问，否则会自动跳到tip
  if (to.path === '/poster' || to.path === '/tip' || _isMobile()) {
    next();
  } else {
    next({ path: '/tip' })
  }
})

export default router
