<template>
  <div
    class="loading"
    v-if="loading"
  >
    <img
      src="../assets/loading.gif"
      alt=""
    >
  </div>
</template>

<script>
export default {
  name: 'loading',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.loading img {
  width: 50px;
}
</style>